export const items = [
    { 
        id: 0, 
        name: 'Полукольцо Р40x20', 
        size: '40х20 мм',
        packaging: '1000 шт',
        img: 'assets/img/p40x20.jpg' 
    },
    { 
        id: 1, 
        name: 'Полукольцо Р27x10/04', 
        size: '27х10 мм',
        packaging: '3000 шт',
        img: 'assets/img/Р27_10d04.jpg' 
    },
    { 
        id: 2, 
        name: 'Полукольцо Р30x22', 
        size: '30х22 мм',
        packaging: '1000 шт',
        img: 'assets/img/Р30x22.jpg' 
    },
    { 
        id: 3, 
        name: 'Полукольцо P30x27', 
        size: '30х27 мм',
        packaging: '1000 шт',
        img: 'assets/img/P30x27.jpg' 
    },
    { 
        id: 4, 
        name: 'Полукольцо P31x19', 
        size: '31х19 мм',
        packaging: '5000 шт',
        img: 'assets/img/P31x19.jpg' 
    },
    { 
        id: 5, 
        name: 'Полукольцо P31x27/4', 
        size: '31х27 мм',
        packaging: '1000 шт',
        img: 'assets/img/P31x27_4.jpg' 
    },
    { 
        id: 6, 
        name: 'Полукольцо P35x30', 
        size: '35,5х30 мм',
        packaging: '1000 шт',
        img: 'assets/img/P35x30.jpg' 
    },
    { 
        id: 7, 
        name: 'Полукольцо P35x25', 
        size: '35,5х25 мм',
        packaging: '1000 шт',
        img: 'assets/img/P35x25.jpg' 
    },
    {
        id: 8, 
        name: 'Полукольцо P39x25', 
        size: '39х25 мм',
        packaging: '1000 шт',
        img: 'assets/img/P39x25.jpg' 
    },
    { 
        id: 9, 
        name: 'Полукольцо P39x25/4', 
        size: '39х25 мм',
        packaging: '1000 шт',
        img: 'assets/img/P39x25_4.jpg' 
    },
    { 
        id: 10, 
        name: 'Полукольцо P40x30', 
        size: '40х30 мм',
        packaging: '1000 шт',
        img: 'assets/img/P40x30.jpg' 
    },
    { 
        id: 11, 
        name: 'Полукольцо P46x34', 
        size: '46х34 мм',
        packaging: '300 шт',
        img: 'assets/img/P46x34.jpg' 
    },
    { 
        id: 12, 
        name: 'Полукольцо P51x38', 
        size: '51х38 мм',
        packaging: '500 шт',
        img: 'assets/img/P51x38.jpg'  
    },
    { 
        id: 13, 
        name: 'Полукольцо P66x53', 
        size: '66х53 мм',
        packaging: '300 шт',
        img: 'assets/img/P66x53.jpg'   
    },
    { 
        id: 14, 
        name: 'Полукольцо P20x10 (ГОСТ В 17690-91)', 
        size: '20х10 мм',
        packaging: '1000 шт',
        img: 'assets/img/P20x10.jpg' 
    },
    { 
        id: 15, 
        name: 'Полукольцо P34x25', 
        size: '34х25 мм',
        packaging: '1000 шт',
        img: 'assets/img/P34x25.jpg' 
    },
    { 
        id: 16, 
        name: 'Полукольцо P17x15', 
        size: '17х15 мм',
        packaging: '1000 шт',
        img: 'assets/img/P17x15.jpg' 
    },
    { 
        id: 17, 
        name: 'Полукольцо P42x24', 
        size: '42х24 мм',
        packaging: '1000 шт',
        img: 'assets/img/P42x24.jpg'  
    },
    { 
        id: 18, 
        name: 'Полукольцо P46x34/4', 
        size: '46х34 мм',
        packaging: '300 шт',
        img: 'assets/img/P46x34_4.jpg'  
    },
    { 
        id: 19, 
        name: 'Полукольцо P30x22/4', 
        size: '30х22 мм',
        packaging: '1000 шт',
        img: 'assets/img/P30x22_4.jpg'
    },
    { 
        id: 20, 
        name: 'Полукольцо P15x20', 
        size: '15х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P15x20.jpg'
    },
    { 
        id: 21, 
        name: 'Полукольцо P18x9', 
        size: '18,5х9 мм',
        packaging: '2000 шт',
        img: 'assets/img/P18x9.jpg'
    },
    { 
        id: 22, 
        name: 'Полукольцо P20x15', 
        size: '20х15 мм',
        packaging: '5000 шт',
        img: 'assets/img/P20x15.jpg'
    },
    { 
        id: 23, 
        name: 'Полукольцо P20x20', 
        size: '20х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P20x20.jpg'
    },
    { 
        id: 24, 
        name: 'Полукольцо P20x20/4', 
        size: '20х20 мм',
        packaging: '1000 шт',
        img: 'assets/img/P20x20_4.jpg'
    },
    { 
        id: 25, 
        name: 'Полукольцо P20x15/2', 
        size: '20х15 мм',
        packaging: '5000 шт',
        img: 'assets/img/P20x15_2.jpg'
    },
    { 
        id: 26, 
        name: 'Полукольцо P20x15/1', 
        size: '20,5х15 мм',
        packaging: '3000 шт',
        img: 'assets/img/P20x15_1.jpg'
    },
    { 
        id: 27, 
        name: 'Полукольцо P25x25', 
        size: '25х25 мм',
        packaging: '2000 шт',
        img: 'assets/img/P25x25.jpg'
    },
    { 
        id: 28, 
        name: 'Полукольцо P25x17', 
        size: '25х17 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x17.jpg'
    },
    { 
        id: 29, 
        name: 'Полукольцо P25x20/2', 
        size: '25,5х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x20_2.jpg'
    },
    { 
        id: 30, 
        name: 'Полукольцо P25x25/2', 
        size: '25,5х25 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x25_2.jpg'
    },
    { 
        id: 31, 
        name: 'Полукольцо P26x20', 
        size: '26х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x20.jpg'
    },
    { 
        id: 32, 
        name: 'Полукольцо P26x20/2', 
        size: '26х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x20_2.jpg'
    },
    { 
        id: 33, 
        name: 'Полукольцо P26x20/3', 
        size: '26х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x20_3.jpg'
    },
    { 
        id: 34, 
        name: 'Полукольцо P26x20/4', 
        size: '26х10 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x20_4.jpg' 
    },
    { 
        id: 35, 
        name: 'Полукольцо P26x20', 
        size: '26х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x20_1.jpg'  
    },
    { 
        id: 36, 
        name: 'Полукольцо P26x16', 
        size: '26х16 мм',
        packaging: '3000 шт',
        img: 'assets/img/P26x16.jpg' 
    },
    { 
        id: 37, 
        name: 'Полукольцо P51x38', 
        size: '51х38 мм',
        packaging: '500 шт',
        img: 'assets/img/P51x38_1.jpg' 
    },
    { 
        id: 38, 
        name: 'Полукольцо P46x34', 
        size: '46х34 мм',
        packaging: '300 шт',
        img: 'assets/img/P46x34_1.jpg' 
    },
    { 
        id: 39, 
        name: 'Полукольцо P39x25', 
        size: '39х25 мм',
        packaging: '1000 шт',
        img: 'assets/img/P39x25_1.jpg' 
    },
    { 
        id: 40, 
        name: 'Полукольцо P35x30/2', 
        size: '35,5х30 мм',
        packaging: '1000 шт',
        img: 'assets/img/P35x30_2.jpg' 
    },
    { 
        id: 41, 
        name: 'Полукольцо P30x27', 
        size: '30х27 мм',
        packaging: '1000 шт',
        img: 'assets/img/P30x27_1.jpg' 
    },
    { 
        id: 42, 
        name: 'Полукольцо P25x25/2', 
        size: '25,5х25 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x25_21.jpg' 
    },
    { 
        id: 43, 
        name: 'Полукольцо P20x20/4', 
        size: '20х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P20x20_41.jpg'  
    },
    { 
        id: 44, 
        name: 'Полукольцо P15x20', 
        size: '15х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P15x20_1.jpg'  
    },
    { 
        id: 45, 
        name: 'Полукольцо P20x20/1', 
        size: '20х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P20x20_1.jpg'  
    },
    { 
        id: 46, 
        name: 'Полукольцо P25x25/2', 
        size: '25х25 мм',
        packaging: '2000 шт',
        img: 'assets/img/P25x25_22.jpg'  
    },
    { 
        id: 47, 
        name: 'Полукольцо P25x20/2', 
        size: '25,5х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x20_22.jpg'  
    },
    { 
        id: 49, 
        name: 'Полукольцо P25x20/2.', 
        size: '25,5х20 мм',
        packaging: '3000 шт',
        img: 'assets/img/P25x20_23.jpg'  
    },
    { 
        id: 50, 
        name: 'Полукольцо P20x20/3', 
        size: '20х20 мм',
        packaging: '2000 шт',
        img: 'assets/img/P20x20_3.jpg'  
    },
];