/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import './App.css';
import { items } from './data';
import { useState, useRef, React } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useNumberInput,
  Button,
  HStack,
  Input,
  Heading,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { Card, CardBody, CardFooter } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'

import {
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

import { useForm } from "react-hook-form"

function App() {
  const [activeItem, setActiveItem] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [inForm, setInForm] = useState(false);
  const element = items.find((el, i) => i === activeItem);
  const [basket, setBasket] = useState([]);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 100,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  function addItemBasket() {
    const even = (el) => el.item.id === element.id;

    if (!basket.some(even)) {
      setBasket([
        ...basket,
        { item: element, quantity: input.value }
      ])
      setIsVisible2(true)
    } else {
      setIsVisible(true)
      setIsVisible2(false)
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  function deleteItem(id) {
    setBasket(basket.filter(obj => obj.item.id !== id));
  }

  //form
  const { register, handleSubmit } = useForm()

  const handleNext = (data) => {
    setBasket([]);
    setInForm(true)
    fetch('https://prom.gkruss.ru/form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        basket: basket, client: data
      })
    })
  };

  return (
    <div className="App">
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Корзина</DrawerHeader>
          {inForm !== true &&
            <DrawerBody>
              <div>
                <FormControl>
                  <div>
                    <FormLabel>Имя *</FormLabel>
                    <input className="form-control" {...register('name', { required: true })} type='text' placeholder='Имя' />
                  </div>
                  <div className='mt-3'>
                    <FormLabel>Email</FormLabel>
                    <input className="form-control" {...register('email')} type='email' placeholder='Ваш Email' />
                  </div>
                  <div className='mt-3'>
                    <FormLabel>Телефон *</FormLabel>
                    <input className="form-control" {...register('phone', { required: true })} type='tel' placeholder='Номер телефона' />
                  </div>
                </FormControl>
              </div>
              <hr></hr>
              {basket.map(employee => {
                return (
                  <Card
                    key={employee.item.id}
                    direction={{ base: 'column', sm: 'row' }}
                    // overflow='hidden'
                    variant='outline'
                    mt={2}
                  >
                    <Image
                      objectFit='cover'
                      maxW={{ base: '100%', sm: '200px' }}
                      src={employee.item.img}
                      alt='Caffe Latte'
                    />

                    <Stack>
                      <CardBody>
                        <Heading size='md'>{employee.item.name}</Heading>
                        <p>Количество: {employee.quantity} </p>
                      </CardBody>

                      <CardFooter>
                        <Button variant='solid' colorScheme='red' onClick={() => deleteItem(employee.item.id)}>
                          Удалить
                        </Button>
                      </CardFooter>
                    </Stack>
                  </Card>
                )
              })}
            </DrawerBody>
          }

          {inForm === true &&
            <DrawerBody>
              <div className="alert alert-success" role="alert">
                Менеджер скоро свяжится с вами
              </div>
            </DrawerBody>
          }


          <DrawerFooter>
            <form onSubmit={handleSubmit(handleNext)}>
              <Button type="submit" isDisabled={basket.length === 0} colorScheme='green'>Рассчитать заказ</Button>
            </form>
          </DrawerFooter>

        </DrawerContent>
      </Drawer>


      <nav className="navbar navbar-light navbar-expand-lg fixed-top bg-white clean-navbar">
        <div className="container">
          <img src='logo.jpg' style={{ height: 60 }}></img> <p className='gk-logo'>ГК РУСС</p> <button data-bs-toggle="collapse"
            className="navbar-toggler" data-bs-target="#navcol-1"><span className="visually-hidden">
            </span><span className="navbar-toggler-icon"></span></button>
          <Button border='1px' borderColor='green.500' className='navbar-toggler' ref={btnRef} colorScheme='gray' onClick={onOpen}>
            Корзина
          </Button>
          <div className="collapse navbar-collapse" id="navcol-1">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><a className="nav-link" href="#ADVANTAGES">Преймушества</a></li>
              <li className="nav-item"><a className="nav-link" href="#PRICE">Прайс лист</a></li>
              <li className="nav-item"><a className="nav-link" href="#company">Контакты</a></li>
              <Button border='1px' borderColor='green.500' ref={btnRef} colorScheme='gray' onClick={onOpen}>
                Корзина
              </Button>
            </ul>
          </div>
        </div>
      </nav>
      <main className="page landing-page">
        <section className="clean-block clean-info dark">
          <div className="container">
            <div className="block-heading">
              <section className="py-4 py-xl-5">
                <div className="container">
                  <div className="bg-dark border rounded border-0 border-dark overflow-hidden">
                    <div className="row g-0">
                      <div className="col-md-6 mheder">
                        <div className="text-white p-4 p-md-5">
                          <h2 className="fw-bold text-white mb-3">Полукольца от производителя
                          </h2>
                          <p className="mb-4">Мы всегда открыты для прямого сотрудничества!
                            Это выгодно для Вас, так как Вы тратите меньше денег и времени,
                            а также получаете гарантию качества на всю продукцию. Для нас это выгодно тем,
                            что мы получаем обратную связь, расширяем свою клиентскую базу и создаем положительную репутацию своего производства</p>
                        </div>
                      </div>
                      <div className="col-md-6 order-first order-md-last"><img
                        className="w-100 h-100 fit-cover"
                        src="https://www.sodbik.ru/upload/iblock/29d/f369twv30khlxs0z6oxbznwil75wpdm2/a00852e1_c516_11ea_9467_708bcd80570f_94554a4e_c5d8_11ea_9469_708bcd80570f.resize1.png"></img></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <section className="clean-block features" id='ADVANTAGES'>
          <div className="container">
            <div className="block-heading">
              <h2 className="">Почему стоит заказать на производстве</h2>
            </div>
            <div className="row justify-content-center text-start">
              <div className="col-md-5 feature-box"><i className="icon-star icon"></i>
                <h4>Отсутствие посредников</h4>
                {/* <p>При заказе у производителя отсудствует комиссия 3-х лиц</p> */}
              </div>
              <div className="col-md-5 feature-box"><i className="icon-star icon"></i>
                <h4>Высокая скорость получение товара</h4>
                {/* <p>На производстве имеется.</p> */}
              </div>
              <div className="col-md-5 feature-box"><i className="icon-star icon"></i>
                <h4>Гарантии качества</h4>
                {/* <p>.</p> */}
              </div>
              <div className="col-md-5 feature-box"><i className="icon-star icon"></i>
                <h4>Возможность индивидуальных заказов</h4>
                <p>.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="clean-block slider dark pt-4" id='PRICE'>
          <div className="container pt-4">
            <div className="row">
              <div className="col-sm-12 col-md-7">
                <div className="gridItems">
                  {items.map(employee => {
                    return (
                      <div key={employee.id}>
                        <div className="card" style={activeItem === employee.id ? { borderColor: 'blue' } : { padding: 3 }} onClick={() => (setActiveItem(employee.id), setIsVisible(false), setIsVisible2(false))}>
                          <img src={employee.img} className="card-img-top" alt="..."></img>
                          <div className="card-body">
                            <h5> {employee.name} </h5>
                            <div>
                            </div>
                            {/* <p className="card-text mt-4">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm-12 col-md-5">
                <div className='pt-4 calc'>
                  <div className='imgCentr'><img src={element.img} width="50%"></img></div>
                  <div>
                    <h5 className="mt-4">{element.name}</h5>
                    <table className="table table-striped">
                      <tbody>

                        {typeof element.material !== "undefined" &&
                          <tr>
                            <td>Материал</td>
                            <td>{element.material}</td>
                          </tr>
                        }
                        {typeof element.size !== "undefined" &&
                          <tr>
                            <td>Размеры</td>
                            <td>{element.size}</td>
                          </tr>
                        }
                        {typeof element.сolour !== "undefined" &&
                          <tr>
                            <td>Цвет</td>
                            <td>{element.сolour}</td>
                          </tr>
                        }
                        {typeof element.form !== "undefined" &&
                          <tr>
                            <td>Форма</td>
                            <td>{element.form}</td>
                          </tr>
                        }
                        {typeof element.weight !== "undefined" &&
                          <tr>
                            <td>Вес</td>
                            <td>{element.weight}</td>
                          </tr>
                        }
                        {typeof element.packaging !== "undefined" &&
                          <tr>
                            <td>Штук в упаковке</td>
                            <td>{element.packaging}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    <div className='text-centr HookUsage'>
                      <HStack maxW='320px'>
                        <Button {...dec}>-</Button>
                        <Input {...input} />
                        <Button {...inc}>+</Button>
                      </HStack>
                    </div>
                    <p>Необходимое количество упаковок</p>
                    <div className='pb-4 pt-2'>
                      <Button onClick={() => (addItemBasket())} colorScheme='gray' border='1px' borderColor='green.500'>Узнать стомиость</Button>
                    </div>
                    {isVisible ? (<div className="alert alert-warning" role="alert">
                      Товаар уже в карзине
                    </div>) : ''}
                    {isVisible2 ? (<div className="alert alert-success" role="alert">
                      Товаар добавлен в карзину
                    </div>) : ''}

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Button className='mt-5' size='lg' colorScheme='gray' border='2px' borderColor='green.500'>Получить прайс-лист</Button> */}
        </section>
        <section className="position-relative py-4 py-xl-5" id='company'>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 col-lg-4 col-xl-4">
                <div className="d-flex flex-column justify-content-center align-items-start h-100 text-sm-start">
                  <div className="d-flex align-items-center p-3">
                    <div
                      className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        viewBox="0 0 16 16" className="bi bi-telephone">
                        <path
                          d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z">
                        </path>
                      </svg></div>
                    <div className="px-2">
                      <h6 className="mb-0">Телефон</h6>
                      <p className="mb-0">8 (937) 070-77-94</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <div
                      className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        viewBox="0 0 16 16" className="bi bi-envelope">
                        <path fillRule="evenodd"
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z">
                        </path>
                      </svg></div>
                    <div className="px-2">
                      <h6 className="mb-0">Email</h6>
                      <p className="mb-0">prom@gkruss.ru</p>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center p-3">
                    <div
                      className="bs-icon-md bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        viewBox="0 0 16 16" className="bi bi-pin">
                        <path
                          d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z">
                        </path>
                      </svg></div>
                    <div className="px-2">
                      <h6 className="mb-0">Офис</h6>
                      <p className="mb-0">12 Example Street</p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div>
                  <form className="p-3 p-xl-4" onSubmit={handleSubmit(handleNext)}>
                    <div className="mb-3"><input {...register('name', { required: true })} className="form-control" type="text" id="name-1" name="name"
                      placeholder="Имя"></input></div>
                    <div className="mb-3"><input {...register('email')} className="form-control" type="email" id="email-1" name="email"
                      placeholder="Email"></input></div>
                    <div className="mb-3"><textarea {...register('message')} className="form-control" id="message-1" name="message" rows="6"
                      placeholder="Ваше предложение или вопрос"></textarea></div>
                    <div>
                      <Button className="btn d-block w-100" type='submit' colorScheme='gray' border='1px' borderColor='green.500'>
                        Отправить
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="footer-copyright">
          <p>© 2024 Copyright ООО ГК "РУСС"</p>
        </div>
      </footer>
    </div >
  );
}

export default App;
